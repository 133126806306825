import {ComponentProps, ElementType, ReactNode} from 'react';
import {Logo} from '~/routes/$lang._public/Logo';

import {cn} from '~/utils';

type HeaderProps = {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  app?: boolean;
};

export const Header = ({title, children, className, app}: HeaderProps) => {
  return (
    <header
      className={cn(
        className,
        'items-center flex-row flex z-30 sm:pr-8 pr-2 py-6 backdrop-blur-md bg-card/20 fixed top-0 left-0 right-0',
        'shadow-sm justify-between pl-4',
        app ? 'sm:pl-72' : 'sm:pl-16',
      )}>
      {title && (
        <h1 className="text-2xl font-bold text-primary text-center flex flex-row justify-center items-center">
          {title}
        </h1>
      )}
      {children}
    </header>
  );
};

type HeaderTitleProps<El extends ElementType = 'div'> = {
  subtitle?: string;
  src: string;
  as?: El;
} & Omit<ComponentProps<El>, 'logo' | 'subtitle' | 'as'>;

export const HeaderTitle = <El extends ElementType>({src, subtitle, as, className, ...props}: HeaderTitleProps<El>) => {
  const Component = as || 'div';
  return (
    <Component className={cn('gap-4 flex flex-row absolute left-8 sm:left-24 items-center', className)} {...props}>
      <Logo className="h-8 w-32 text-[#4c7fbb]" />
    </Component>
  );
};
